import {
  AdjustmentsVerticalIcon,
  ArrowUpTrayIcon,
  BanknotesIcon,
  BellAlertIcon,
  BellIcon,
  BuildingOfficeIcon,
  ChartBarSquareIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  Cog6ToothIcon,
  CogIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  FolderIcon,
  HomeIcon,
  HomeModernIcon,
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  QueueListIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  Square3Stack3DIcon,
  StarIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  VideoCameraIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { BookOpenIcon } from '@heroicons/react/24/solid';

import { CanvazaLightIcon, CloudSSOIcon } from '../utils/svg';

export const ROOT = { url: '/', name: 'Main Page', icon: CanvazaLightIcon };
export const LOGIN = { url: '/login', name: 'Sign In', icon: LockClosedIcon };
export const SSO = {
  url: `${LOGIN.url}/sso`,
  name: 'Sign in with SSO',
  icon: CloudSSOIcon,
};
export const PRICING = {
  url: '/pricing',
  name: 'Pricing',
  icon: CurrencyDollarIcon,
};
export const ABOUT = {
  url: '/about',
  name: 'About',
  icon: HomeModernIcon,
};
export const PRIVACY_POLICY = {
  url: '/privacy-policy',
  name: 'Privacy Policy',
  icon: ClipboardDocumentListIcon,
};
export const TERMS_AND_CONDITIONS = {
  url: '/terms-and-conditions',
  name: 'Terms and Condition',
  icon: ClipboardDocumentListIcon,
};
export const REGISTER = {
  url: '/register',
  name: 'Sign up',
  icon: UserGroupIcon,
};
export const USER_INVITATION = {
  url: '/user-invitation',
  name: 'Invited User',
  icon: UserIcon,
};
export const FORGOT_PASSWORD = {
  url: '/forgot-password',
  name: 'Forgot Password',
  icon: LockOpenIcon,
};
export const HOME = { url: '/home', name: 'Home', icon: HomeIcon };
export const LIBRARY = {
  url: '/library',
  name: 'Library',
  icon: Square3Stack3DIcon,
};
export const UPLOAD = {
  url: `${LIBRARY.url}/upload`,
  name: 'Upload',
  icon: ArrowUpTrayIcon,
};
export const TRASH = { url: '/trash', name: 'Trash', icon: TrashIcon };
export const STARRED = { url: '/starred', name: 'Starred', icon: StarIcon };
export const WORKSPACE = {
  url: '/workspace',
  name: 'Workspace setting',
  icon: Cog6ToothIcon,
};
export const NEW_WORKSPACE = {
  url: `${WORKSPACE.url}/new-workspace`,
  name: 'New Workspace',
  icon: BuildingOfficeIcon,
};
export const EDIT_WORKSPACE = {
  url: `${WORKSPACE.url}/edit`,
  name: 'Edit',
  icon: BuildingOfficeIcon,
};

export const MEMBERS = { url: '/members', name: 'Members', icon: UsersIcon };
export const PROFILE_SETTING = {
  url: '/profile-settings',
  name: 'Profile settings',
  icon: CogIcon,
};
export const COLLECTION = {
  url: '/collection',
  name: 'Collection',
  icon: FolderIcon,
};
export const ITEM = { url: '/item', name: 'Video', icon: VideoCameraIcon };
export const INVITATION = {
  url: '/invitations',
  name: 'Invitations',
  icon: EnvelopeOpenIcon,
};

export const ANALYTICS = {
  url: `/analytics`,
  name: 'Analytics',
  icon: ChartBarSquareIcon,
};
export const HISTORY = { url: '/history', name: 'History', icon: ClockIcon };
export const CONTACT = { url: '/contact', name: 'Contact', icon: EnvelopeIcon };
export const GENERAL_SETTINGS = {
  url: '/general-settings',
  name: 'General Settings',
  icon: CogIcon,
};
export const PLANS = {
  url: '/plans',
  name: 'Plans',
  icon: ClipboardDocumentCheckIcon,
};
export const UPGRADE = {
  url: '/upgrade',
  name: 'Upgrade Plan',
  icon: Square3Stack3DIcon,
};

export const PLAN_OVERVIEW = {
  url: '/plan-overview',
  name: 'Plan Overview',
  icon: ViewColumnsIcon,
};

export const NEW_PLAN = {
  url: `${PLANS.url}/new-plan`,
  name: 'New Plan',
  icon: CubeTransparentIcon,
};
export const ROLES = {
  url: '/roles',
  name: 'Roles',
  icon: AdjustmentsVerticalIcon,
};
export const USERS = { url: '/users', name: 'Users', icon: UserGroupIcon };
export const REQUESTS = {
  url: '/requests',
  name: 'Requests',
  icon: QueueListIcon,
};
export const ONBOARD = { url: '/onboard', name: 'Onboard', icon: HomeIcon };
export const CHECK_VERIFY_EMAIL = {
  url: '/check-verify-email',
  name: 'Check Verify Email',
  icon: EnvelopeOpenIcon,
};
export const FEEDBACK = {
  url: '/feedback',
  name: 'Feedback',
  icon: EnvelopeOpenIcon,
};

export const NOTIFICATIONS = {
  url: '/notifications',
  name: 'Notifications',
  icon: BellAlertIcon,
};

const SETTING = '/setting';
export const PERSONAL_SETTING = {
  url: `${SETTING}/personal`,
  name: 'Personal Settings',
  icon: UserIcon,
};

export const NOTIFICATION_SETTING = {
  url: `${SETTING}/notification`,
  name: 'Notification Settings',
  icon: BellIcon,
};

export const SECURITY_SETTING = {
  url: `${SETTING}/security`,
  name: 'Login and Security',
  icon: LockClosedIcon,
};

export const WORKSPACE_SETTING = {
  url: `${SETTING}/workspace`,
  name: 'Workspace',
  icon: RectangleGroupIcon,
};

export const PAYMENT_METHOD_SETTING = {
  url: `${SETTING}/payment-methods`,
  name: 'Payment Methods',
  icon: CreditCardIcon,
};

export const SUBSCRIPTION_SETTING = {
  url: `${SETTING}/subscriptions`,
  name: 'Subscriptions',
  icon: RectangleStackIcon,
};

const CASE_STUDY = '/casestudy';

export const EDUCATION = {
  url: `${CASE_STUDY}/education`,
  name: 'Education',
  icon: BookOpenIcon,
};

export const ENGINEERING = {
  url: `${CASE_STUDY}/engineering`,
  name: 'Engineering',
  icon: RectangleGroupIcon,
};

export const SALES = {
  url: `${CASE_STUDY}/sales`,
  name: 'Sales',
  icon: BanknotesIcon,
};

export const HELP_SUPPORT = {
  url: `/help-and-support`,
  name: 'Help and support',
  icon: InformationCircleIcon,
};

export const GET_STARTED_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/get-started`,
  name: 'Get started',
  icon: VideoCameraIcon,
};

export const INVITE_FRIENDS_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/invite-friends`,
  name: 'Invite friends',
  icon: VideoCameraIcon,
};

export const MANAGE_WORKSPACE_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/manage-workspace`,
  name: 'Manage workspace',
  icon: VideoCameraIcon,
};

export const VIDEO_SHARING_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/video-sharing`,
  name: 'Video sharing',
  icon: VideoCameraIcon,
};

export const VIDEO_RECORDING_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/video-recording`,
  name: 'Video recording',
  icon: VideoCameraIcon,
};
export const UPGRADE_PLAN_TUTORIAL = {
  url: `${HELP_SUPPORT.url}/upgrade-plan`,
  name: 'Upgrade plan',
  icon: VideoCameraIcon,
};

export const RouteNavsWithKey = {
  [ROOT.url]: ROOT,
  [LOGIN.url]: LOGIN,
  [SSO.url]: SSO,
  [REGISTER.url]: REGISTER,
  [FORGOT_PASSWORD.url]: FORGOT_PASSWORD,

  [HOME.url]: HOME,
  [LIBRARY.url]: LIBRARY,
  [UPLOAD.url]: UPLOAD,
  [TRASH.url]: TRASH,
  [STARRED.url]: STARRED,
  [WORKSPACE.url]: WORKSPACE,
  [NEW_WORKSPACE.url]: NEW_WORKSPACE,
  [EDIT_WORKSPACE.url]: EDIT_WORKSPACE,

  [INVITATION.url]: INVITATION,

  [MEMBERS.url]: MEMBERS,
  [PROFILE_SETTING.url]: PROFILE_SETTING,
  [COLLECTION.url]: COLLECTION,
  [ITEM.url]: ITEM,
  [ANALYTICS.url]: ANALYTICS,
  [HISTORY.url]: HISTORY,
  [CONTACT.url]: CONTACT,
  [GENERAL_SETTINGS.url]: GENERAL_SETTINGS,

  [PLANS.url]: PLANS,
  [UPGRADE.url]: UPGRADE,
  [NEW_PLAN.url]: NEW_PLAN,
  [ROLES.url]: ROLES,
  [USERS.url]: USERS,
  [REQUESTS.url]: REQUESTS,
  [ONBOARD.url]: ONBOARD,
  [CHECK_VERIFY_EMAIL.url]: CHECK_VERIFY_EMAIL,
  [FEEDBACK.url]: FEEDBACK,
  [NOTIFICATIONS.url]: NOTIFICATIONS,

  [PERSONAL_SETTING.url]: PERSONAL_SETTING,
  [NOTIFICATION_SETTING.url]: NOTIFICATION_SETTING,
  [SECURITY_SETTING.url]: SECURITY_SETTING,
  [WORKSPACE_SETTING.url]: WORKSPACE_SETTING,
  [PAYMENT_METHOD_SETTING.url]: PAYMENT_METHOD_SETTING,
  [SUBSCRIPTION_SETTING.url]: SUBSCRIPTION_SETTING,

  [USER_INVITATION.url]: USER_INVITATION,

  [PLAN_OVERVIEW.url]: PLAN_OVERVIEW,
};

export const RouterNavs = {
  root: ROOT.url,
  auth: {
    login: LOGIN.url,
    sso: SSO.url,
    register: REGISTER.url,
    forgotPassword: FORGOT_PASSWORD.url,
    userInvitation: USER_INVITATION.url,
  },
  public: {
    pricing: PRICING.url,
    about: ABOUT.url,
    contact: CONTACT.url,
    privacyPolicy: PRIVACY_POLICY.url,
    termsAndConditions: TERMS_AND_CONDITIONS.url,
    caseStudy: {
      education: EDUCATION.url,
      sales: SALES.url,
      engineering: ENGINEERING.url,
    },
    helpAndSupport: {
      root: HELP_SUPPORT.url,
      getStarted: GET_STARTED_TUTORIAL.url,
      videoRecording: VIDEO_RECORDING_TUTORIAL.url,
      inviteFriends: INVITE_FRIENDS_TUTORIAL.url,
      manageWorkspace: MANAGE_WORKSPACE_TUTORIAL.url,
      videoSharing: VIDEO_SHARING_TUTORIAL.url,
      upgradePlan: UPGRADE_PLAN_TUTORIAL.url,
    },
  },

  shared: {
    collection: COLLECTION.url,
    item: ITEM.url,
  },
  protected: {
    user: {
      home: HOME.url,
      trashed: TRASH.url,
      library: LIBRARY.url,
      upload: UPLOAD.url,
      starred: STARRED.url,
      upgrade: UPGRADE.url,
      workspace: WORKSPACE.url,
      profileSettings: PROFILE_SETTING.url,
      history: HISTORY.url,
      analytics: ANALYTICS.url,
      members: MEMBERS.url,
      newWorkspace: NEW_WORKSPACE.url,
      invitations: INVITATION.url,
      planOverview: PLAN_OVERVIEW.url,
    },
    admin: {
      generalSettings: GENERAL_SETTINGS.url,
      plans: PLANS.url,
      newPlan: NEW_PLAN.url,
      roles: ROLES.url,
      users: USERS.url,
      requests: REQUESTS.url,
    },
    onboarding: {
      onboard: ONBOARD.url,
      checkVerifyEmail: CHECK_VERIFY_EMAIL.url,
    },
    common: {
      feedback: FEEDBACK.url,
      notification: NOTIFICATIONS.url,
    },
    setting: {
      personalSettings: PERSONAL_SETTING.url,
      notificationSettings: NOTIFICATION_SETTING.url,
      loginAndSecurity: SECURITY_SETTING.url,
      workspaces: WORKSPACE_SETTING.url,
      paymentMethod: PAYMENT_METHOD_SETTING.url,
      subscription: SUBSCRIPTION_SETTING.url,
    },
  },
};
