import type { QueryConstraint, Timestamp } from 'firebase/firestore';
import { FieldPath, where } from 'firebase/firestore';

import type { ICollectionParent } from '@/shared/firebase/models/collection.interface';
import type { IStatus } from '@/shared/firebase/models/common.interface';
import type { IItem } from '@/shared/firebase/models/item.interface';
import { compareDate, compareString } from '@/shared/utils/string';

import { Collection } from '../../constants/collection';
import {
  useBatchDeleteMutation,
  useBatchUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useGetById,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';
import type { LibrarySortBy } from './collection.hooks';

type UseGetItems = {
  userId?: string | null;
  workspaceId?: string | null;
  collectionId?: string | null;
  helper?: boolean;
  status?: IStatus | null;
  itemIds?: string[] | null;
  sort?: LibrarySortBy;
  enabled: boolean;
  key?: string;
};
export const useGetItems = ({
  userId,
  workspaceId,
  collectionId,
  helper,
  itemIds,
  status = { trashed: false, direct: false },
  sort,
  enabled,
  key,
}: UseGetItems) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetItems'];

  if (sort && sort.field) {
    keys.push(sort.field, sort.directionStr ?? 'asc');
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  if (itemIds && itemIds?.length > 0) {
    queries.push(where('id', 'in', itemIds));
  }

  if (workspaceId) {
    queries.push(where('workspaceId', '==', workspaceId));
    keys.push(workspaceId);
  }

  if (helper) {
    queries.push(where('helper', '==', helper));
  }

  if (collectionId !== undefined) {
    if (collectionId) {
      queries.push(
        where(new FieldPath('collection', 'id'), '==', collectionId)
      );
    } else {
      queries.push(where('collection', '==', null));
    }
    keys.push(collectionId || 'collection');
  }

  if (status) {
    queries.push(
      where(new FieldPath('status', 'trashed'), '==', status.trashed)
    );
    queries.push(where(new FieldPath('status', 'direct'), '==', status.direct));
    keys.push(status);
  }

  return useGetQuery<IItem>({
    collectionName: Collection.ITEMS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled,
      select: (items: IItem[]) => {
        if (sort)
          return items.sort((before, next) =>
            compareString(before.name, next.name, sort.directionStr ?? 'asc')
          );
        return items.sort((before, next) =>
          compareDate(
            (before.createdAt as Timestamp).toDate(),
            (next.createdAt as Timestamp).toDate(),
            'desc'
          )
        );
      },
    },
  });
};

export const useGetItemById = (id?: string | null, key?: string) => {
  return useGetById<IItem>({
    collectionName: Collection.ITEMS,
    id,
    key: key ?? 'useGetItemById',
  });
};

export const useCreateItem = (id: string) => {
  return useCreateMutation<IItem>(Collection.ITEMS, id);
};

export const useUpdateItem = (id?: string | null) => {
  return useUpdateMutation<IItem>(Collection.ITEMS, id);
};

export const useRemoveItem = (id?: string | null) => {
  return useDeleteMutation<IItem>(Collection.ITEMS, id);
};

export const useBulkMoveItems = (
  items: IItem[],
  moveToCollection: ICollectionParent | null
) => {
  return useBatchUpdateMutation<IItem>(
    Collection.ITEMS,
    items.map((item) => ({
      ...item,
      collection: moveToCollection
        ? { id: moveToCollection.id, status: moveToCollection.status }
        : null,
    }))
  );
};

export const useBulkTrashItems = (items: IItem[]) => {
  return useBatchUpdateMutation<IItem>(
    Collection.ITEMS,
    items.map((item) => ({
      ...item,
      status: {
        trashed: true,
        direct: true,
      },
    }))
  );
};

export const useBulkDeleteForeverItems = (items: IItem[]) => {
  return useBatchDeleteMutation<IItem>(
    Collection.ITEMS,
    items.map((item) => item.id)
  );
};

export const useBulkRestoreItems = (items: IItem[]) => {
  return useBatchUpdateMutation<IItem>(
    Collection.ITEMS,
    items.map((item) => ({
      ...item,
      status: {
        trashed: false,
        direct: false,
      },
    }))
  );
};

export const useGetGuideItem = (
  sort = {
    field: 'createdAt',
  }
) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [sort.field];

  queries.push(where('helper', '==', true));

  return useGetQuery<IItem>({
    collectionName: Collection.ITEMS,
    queryConstraint: queries,
    keys,
    useQueryOptions: {
      enabled: true,
    },
  });
};
