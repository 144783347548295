import type {
  OrderByDirection,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore';
import { FieldPath, where } from 'firebase/firestore';

import type {
  ICollection,
  ICollectionParent,
  IMutateCollection,
} from '@/shared/firebase/models/collection.interface';
import type { IStatus } from '@/shared/firebase/models/common.interface';
import { compareDate, compareString } from '@/shared/utils/string';

import { Collection } from '../../constants/collection';
import {
  useBatchDeleteMutation,
  useBatchUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useGetById,
  useGetQuery,
  useUpdateMutation,
} from '../helper/firebase-react-query';

export type LibrarySortBy = {
  field?: keyof Pick<ICollection, 'name'>;
  directionStr?: OrderByDirection;
};
type GetCollectionsArgs = {
  userId?: string | null;
  workspaceId?: string | null;
  parentId?: string | null;
  shared?: boolean;
  status?: IStatus;
  sort?: LibrarySortBy;
  enabled: boolean;
  key?: string;
};
export const useGetCollections = ({
  userId,
  workspaceId,
  status = { trashed: false, direct: false },
  parentId,
  shared,
  sort,
  enabled,
  key,
}: GetCollectionsArgs) => {
  const queries: QueryConstraint[] = [];
  const keys: (string | object)[] = [key ?? 'useGetCollections'];

  if (sort && sort.field) {
    keys.push(sort.field, sort.directionStr ?? 'asc');
  }

  if (shared !== undefined || shared !== undefined) {
    queries.push(where('shared', '==', shared));
    keys.push('shared');
  }

  if (userId) {
    queries.push(where(new FieldPath('user', 'id'), '==', userId));
    keys.push(userId);
  }

  if (workspaceId) {
    queries.push(where('workspaceId', '==', workspaceId));
    keys.push(workspaceId);
  }

  if (parentId !== undefined) {
    if (parentId) {
      queries.push(where(new FieldPath('parent', 'id'), '==', parentId));
    } else {
      queries.push(where('parent', '==', null));
    }

    keys.push(parentId || 'parent');
  }

  queries.push(where(new FieldPath('status', 'trashed'), '==', status.trashed));
  queries.push(where(new FieldPath('status', 'direct'), '==', status.direct));
  keys.push(status);

  return useGetQuery<ICollection>({
    collectionName: Collection.COLLECTIONS,
    keys,
    queryConstraint: queries,
    useQueryOptions: {
      enabled,
      select: (collections: ICollection[]) => {
        if (sort)
          return collections.sort((before, next) =>
            compareString(before.name, next.name, sort.directionStr ?? 'asc')
          );
        return collections.sort((before, next) =>
          compareDate(
            (before.createdAt as Timestamp).toDate(),
            (next.createdAt as Timestamp).toDate(),
            'desc'
          )
        );
      },
    },
  });
};

export const useGetCollectionById = (id?: string | null, key?: string) => {
  return useGetById<ICollection>({
    collectionName: Collection.COLLECTIONS,
    id,
    key: key ?? 'useGetCollectionById',
  });
};

export const useCreateCollection = () => {
  return useCreateMutation<IMutateCollection>(Collection.COLLECTIONS);
};

export const useUpdateCollection = (id?: string | null) => {
  return useUpdateMutation<IMutateCollection>(Collection.COLLECTIONS, id);
};

export const useRemoveCollection = (id?: string | null) => {
  return useDeleteMutation<ICollection>(Collection.COLLECTIONS, id);
};

export const useBulkMoveCollections = (
  collections: ICollection[],
  moveToCollection: ICollectionParent | null
) => {
  return useBatchUpdateMutation<ICollection>(
    Collection.COLLECTIONS,
    collections.map((collection) => ({
      ...collection,
      parent: moveToCollection
        ? { id: moveToCollection.id, status: moveToCollection.status }
        : null,
    }))
  );
};

export const useBulkTrashCollections = (collections: ICollection[]) => {
  return useBatchUpdateMutation<ICollection>(
    Collection.COLLECTIONS,
    collections.map((collection) => ({
      ...collection,
      status: {
        trashed: true,
        direct: true,
      },
    }))
  );
};

export const useBulkDeleteForeverCollections = (collections: ICollection[]) => {
  return useBatchDeleteMutation<ICollection>(
    Collection.COLLECTIONS,
    collections.map((collection) => collection.id)
  );
};

export const useBulkRestoreCollections = (collections: ICollection[]) => {
  return useBatchUpdateMutation<ICollection>(
    Collection.COLLECTIONS,
    collections.map((collection) => ({
      ...collection,
      status: {
        trashed: false,
        direct: false,
      },
    }))
  );
};
