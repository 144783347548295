export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export type FullNameSplitterReturnType = {
  firstName: string;
  lastName: string;
};

export const FullNameSplitter = (
  fullName: string | null
): FullNameSplitterReturnType => {
  if (!fullName)
    return {
      firstName: '',
      lastName: '',
    };
  const names = fullName.split(' ');
  const firstName = names[0] ?? null;
  const lastName = names[1] ?? null;
  return {
    firstName: firstName ? capitalizeFirstLetter(firstName) : '',
    lastName: lastName ? capitalizeFirstLetter(lastName) : '',
  };
};

export const removeFileNameExtension = (name: string): string => {
  const fileName = name.split('.');
  fileName.pop();
  fileName.join(',');
  return fileName.toString();
};

export const getFileNameExtension = (filename: string): string => {
  const match = filename.match(/\.([^.]+)$/);
  return (match ? match[1]! : '') ?? '';
};

export const compareString = (
  before: string,
  next: string,
  directionStr: 'asc' | 'desc'
) => {
  const beforeString = before.toLowerCase();
  const nextString = next.toLowerCase();
  if (directionStr === 'asc') {
    if (beforeString < nextString) {
      return -1;
    }
    if (beforeString > nextString) {
      return 1;
    }
  } else {
    if (beforeString > nextString) {
      return -1;
    }
    if (beforeString < nextString) {
      return 1;
    }
  }
  return 0;
};

export const compareDate = (
  before: Date,
  next: Date,
  directionStr: 'asc' | 'desc'
) => {
  if (directionStr === 'asc') {
    return before - next;
  }

  return next - before;
};
